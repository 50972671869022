// eslint-disable-next-line import/no-unresolved
import loadGoogleMapsApi from 'load-google-maps-api';

let map = {};

function setZoomMap(event) {
  if (!event.target.href && (!event.target.parentElement || !event.target.parentElement.parentElement.href)) {
    const target = event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.lat
      ? event.currentTarget
      : event.currentTarget.parentElement;
    const parent = event.detail.dataset && event.detail.dataset.lat ? event.detail : target;

    let zoom = 12;
    if (Object.hasOwnProperty.call(parent.dataset, 'zoom')) {
      zoom = parseFloat(parent.dataset.zoom);
    }
    if (Object.hasOwnProperty.call(parent.dataset, 'initZoom')) {
      zoom = parseFloat(parent.dataset.initZoom);
    }

    map.setZoom(zoom);
    map.setCenter({ lat: parseFloat(parent.dataset.lat, 10), lng: parseFloat(parent.dataset.lng, 10) });
    const eventClickShowMore = new CustomEvent('eventClickShowMore', { detail: parent });
    document.dispatchEvent(eventClickShowMore);
  }
}

function createMap(key) {
  return loadGoogleMapsApi({ key });
}

function getParkingParsed() {
  const parkings = document.querySelectorAll('[data-map="item"]');
  const parkingsParsed = {};

  parkings.forEach((el) => {
    if (!parkingsParsed[el.dataset.city]) {
      parkingsParsed[el.dataset.city] = {
        childrens: {},
      };
    }

    parkingsParsed[el.dataset.city].childrens[el.dataset.id] = {
      lat: el.dataset.lat,
      lng: el.dataset.lng,
    };

    if (el.dataset.zoom) {
      parkingsParsed[el.dataset.city].childrens[el.dataset.id].zoom = el.dataset.zoom;
    }
  });

  return parkingsParsed;
}

function initMap(mapElement, initialCoordinates = null) {
  const parkings = getParkingParsed();
  createMap(mapElement.dataset.key).then((googleMaps) => {
    map = new googleMaps.Map(mapElement, {
      center: {
        lat: initialCoordinates !== null ? parseFloat(initialCoordinates.lat) : 40.4169019,
        lng: initialCoordinates !== null ? parseFloat(initialCoordinates.lng) : -3.7056721,
      },
      zoom: initialCoordinates !== null ? initialCoordinates.zoom : 6,
    });
    const mapMarkers = [];
    const dataMarkers = [];
    const dataMarkersAlwaysVisible = [];
    const dataMarkersCluster = [];
    const icon = '/site/images/icon-map.png';
    let marker;
    let i = 0;
    let markerCluster;
    let ix = 0;

    Object.keys(parkings).forEach((key) => {
      const markersSplit = parkings[key].childrens;
      if (Object.keys(markersSplit).length > 1) {
        let cluster = {};
        const clusterLength = Object.keys(markersSplit).length;
        Object.keys(markersSplit).forEach((prop) => {
          const markerElem = markersSplit[prop];
          if (Object.keys(cluster).length < 1) {
            markerCluster = new googleMaps.Marker({
              position: new googleMaps.LatLng(markerElem.lat, markerElem.lng),
              icon,
              map,
              visible: true,
              id: prop,
              title: markerElem.title,
              label: {
                text: clusterLength.toString(),
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#ffffff',
              },
            });
            cluster = markerCluster;
            googleMaps.event.addListener(markerCluster, 'click', ((markerClusterInvoke) => () => {
              map.setZoom(12);
              map.setCenter({ lat: markerClusterInvoke.getPosition().lat(), lng: markerClusterInvoke.getPosition().lng() });
              const eventSelectCity = new CustomEvent('eventSelectCity', { detail: key });
              document.dispatchEvent(eventSelectCity);
            })(markerCluster, ix));
            ix += ix;
            dataMarkersCluster.push(markerCluster);
          }

          marker = new googleMaps.Marker({
            position: new googleMaps.LatLng(markerElem.lat, markerElem.lng),
            icon,
            map,
            visible: false,
            id: prop,
            title: markerElem.title,
            label: {
              text: 'P',
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#ffffff',
            },
          });
          mapMarkers[prop] = marker;
          googleMaps.event.addListener(marker, 'click', ((markerProp2) => () => {
            const eventSelectParking = new CustomEvent('eventSelectParking', { detail: markerProp2 });
            document.dispatchEvent(eventSelectParking);
          })(marker, i));
          i += i;
          dataMarkers.push(marker);
        });
      } else {
        Object.keys(markersSplit).forEach((propCity) => {
          const markerElem = markersSplit[propCity];
          marker = new googleMaps.Marker({
            position: new googleMaps.LatLng(markerElem.lat, markerElem.lng),
            icon,
            map,
            visible: true,
            id: propCity,
            title: markerElem.title,
            label: {
              text: 'P',
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#ffffff',
            },
          });
          mapMarkers[propCity] = marker;
          googleMaps.event.addListener(marker, 'click', ((markerProp) => () => {
            map.setZoom(13);
            map.setCenter({ lat: markerProp.getPosition().lat(), lng: markerProp.getPosition().lng() });
            const eventSelectParking = new CustomEvent('eventSelectParking', { detail: markerProp });
            document.dispatchEvent(eventSelectParking);
          })(marker, i));
          i += i;
          if (markerElem.zoom) {
            map.setOptions({
              disableDefaultUI: false,
              zoomControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
            });
            map.setZoom(parseInt(markerElem.zoom, 10));
            map.setCenter({ lat: marker.getPosition().lat(), lng: marker.getPosition().lng() });
          }
          dataMarkersAlwaysVisible.push(marker);
        });
      }
    });

    googleMaps.event.addListener(map, 'zoom_changed', () => {
      const zoom = map.getZoom();
      for (i = 0; i < dataMarkers.length; i++) {
        dataMarkers[i].setVisible(zoom > 11);
      }
      for (i = 0; i < dataMarkersCluster.length; i++) {
        dataMarkersCluster[i].setVisible(zoom < 12);
      }
      const eventChangeZoom = new CustomEvent('eventChangeZoom', { detail: zoom });
      document.dispatchEvent(eventChangeZoom);
    });
  });
}

function init() {
  const parkingMap = document.querySelector('[data-widget="parking-map"]');
  if (parkingMap) {
    let initialCoordinates = null;
    const initialCoordinatesEl = document.querySelector('[data-initial-coordinates]');
    if (initialCoordinatesEl) {
      initialCoordinates = JSON.parse(initialCoordinatesEl.dataset.initialCoordinates);
    }
    initMap(parkingMap, initialCoordinates);
  }

  const btnMapZoom = document.querySelectorAll('.js-btn-more-info-map');
  if (btnMapZoom) {
    btnMapZoom.forEach((el) => {
      el.addEventListener('click', setZoomMap);
    });
  }

  document.addEventListener('eventSetZoomMapClick', setZoomMap);
}

export default init();
