const plateInputs = document.querySelectorAll('.js-form__plate');
const phonesInputs = document.querySelectorAll('.js-form__phones');
const nifInputs = document.querySelectorAll('.js-num-document');

function checkLicenceCar(value) {
  if (value.length === 0 && value.length > 12) {
    return false;
  }

  if (value.match(/^[A-Z\d]+$/i) === null) {
    return false;
  }
  return true;
}

function validatePlateInputs() {
  let isValid = true;
  plateInputs.forEach((input) => {
    const span = input.parentElement.querySelector('.o-form__plate');
    if (!checkLicenceCar(input.value)) {
      if (span) {
        span.classList.add('o-form__error');
      }
      isValid = false;
    } else if (span) {
      span.classList.remove('o-form__error');
    }
  });
  return isValid;
}

function replaceLicenceCar(event) {
  const input = event.target;
  if (input.value) {
    input.value = input.value.replace(/[-\s*()=¿?¡!@'`{}.,;#%&/\\<>+_]/i, '').toUpperCase();
    input.value = input.value.substr(0, 12);
  }
}

function replaceNIF(event) {
  const input = event.target;
  if (input.value) {
    input.value = input.value.toUpperCase();
  }
}

function validatePhoneInput(event) {
  const input = event.target;
  const span = input.parentElement.querySelector('span[data-error-type="rule"]');
  invalid = false;

  if (input.dataset.rule && input.attributes.required) {
    if (!evaluateRule(input.value, input.dataset.rule)) {
      invalid = true;
    }
    if (input.dataset.max && input.value.length > input.dataset.max) {
      invalid = true;
    }
  } else if (input.dataset.rule && !input.attributes.required) {
    if (input.value.length > 0) {
      if (!evaluateRule(input.value, input.dataset.rule)) {
        invalid = true;
      }
      if (input.dataset.max && input.value.length > input.dataset.max) {
        invalid = true;
      }
    }
  }
  if (invalid && span) {
    span.classList.remove('u-hidden');
  } else if (!invalid && span) {
    span.classList.add('u-hidden');
    const parent = input.parentElement;
    if (parent.classList.contains('o-form__form-control')) {
      parent.classList.remove('has-error');
    }
  }
}

function evaluateRule(value, rule) {
  const regex = new RegExp(rule);
  return regex.test(value.trim());
}

export function validateInputs() {
  let isValid = true;
  isValid = validatePlateInputs();
  return isValid;
}

function init() {
  plateInputs.forEach((input) => {
    input.addEventListener('keyup', (event) => {
      replaceLicenceCar(event);
    });
  });

  phonesInputs.forEach((input) => {
    input.addEventListener('keyup', (event) => {
      validatePhoneInput(event);
    });
  });

  nifInputs.forEach((input) => {
    input.addEventListener('keyup', (event) => {
      replaceNIF(event);
    });
  });
}

export default init();
