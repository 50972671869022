export function getParentWithClass(node, className) {
  let nodeUse = node.parentElement;
  while (nodeUse) {
    if (nodeUse && nodeUse.classList && nodeUse.classList.contains(className)) {
      return nodeUse;
    }
    nodeUse = nodeUse.parentElement;
  }
  return null;
}

export function numberFormat(number, decimals = 2, decimalSeparator = ',', thousandsSeparator = '.') {
  let finalNumber = parseFloat(number).toFixed(decimals);
  finalNumber = finalNumber.replace('.', decimalSeparator);

  const splitNum = finalNumber.split(decimalSeparator);
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
  return splitNum.join(decimalSeparator);
}

export function normalizeSearchValue(text) {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replaceAll('-', '')
    .replace(/\s+/g, ' ')
    .trim()
    .toLowerCase();
}

const utils = {
  getParentWithClass,
  numberFormat,
  normalizeSearchValue,
};

export default utils;
