import formValidator from '../modules/a_FormValidator';

const formSelector = '#js-form-contract';

function submitForm(event) {
    event.preventDefault();
    const form = document.querySelector(formSelector);
    if (!formValidator.validate(formSelector)) {
        return false;
    }

    form.submit();
    return true;
}

function init() {
    const form = document.querySelector(formSelector);
    if (form) {
        form.addEventListener('submit', submitForm);
    }
}

export default init();
