function toggleDisplay(event) {
  event.preventDefault();
  const element = document.querySelector(".js-parent-wrapper");
  if (!element.classList.contains("is-active")) {
    element.classList.add("is-active");
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
  } else {
    element.classList.remove("is-active");
    document.body.style.overflow = "initial";
    document.body.style.removeProperty("position");
  }
}

function checkButtonVisible(element) {
  const text = element.previousElementSibling;
  if (text.offsetHeight > 42) {
    text.classList.add("c-cards__wrapper-text--max-height");
    element.classList.remove("u-hidden");
  }
}

function init() {
  const dropDownArrow = document.querySelectorAll(".js-nav");
  if (dropDownArrow) {
    dropDownArrow.forEach((el) => {
      el.addEventListener("click", toggleDisplay);
    });
  }

  setTimeout(() => {
    const butttonBlocks = document.querySelectorAll(".js-more-info");
    if (butttonBlocks) {
      butttonBlocks.forEach((el) => {
        checkButtonVisible(el);
      });
    }
  }, 500);
}

export default init();
