import formValidator from '../modules/a_FormValidator';

const formSelector = '#js-form-space';

function submitForm(event) {
  event.preventDefault();
  const form = document.querySelector(formSelector);
  if (!formValidator.validate(formSelector)) {
    return false;
  }
  form.submit();
  return true;
}

function init() {
  const body = document.querySelector('body');
  if (!body.dataset.route.match(/sell/g)) {
    return false;
  }
  const form = document.querySelector(formSelector);
  if (form) {
    form.addEventListener('submit', submitForm);
  }
  return true;
}

export default init();
