import formValidator from '../modules/a_FormValidator';

const formSelector = '#js-form-contact';

function submitForm(event) {
  event.preventDefault();
  const isValid = formValidator.validate(formSelector);
  const form = document.querySelector(formSelector);
  if (isValid) {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'Lead');
    }
    form.submit();
    return true;
  }
  return false;
}

function submitReview() {
  // event.target.submit();
  return true;
}

function setStars(event) {
  const element = event.currentTarget;
  const stars = Array.from(element.parentElement.querySelectorAll('li'));
  const index = stars.indexOf(element);

  stars.forEach((el, i) => {
    if (i <= index) {
      el.classList.add('is-active');
    } else {
      el.classList.remove('is-active');
    }
  });

  submitReview();
}

function init() {
  const form = document.querySelector(formSelector);
  if (form) {
    form.addEventListener('submit', submitForm);
  }

  const stars = document.querySelectorAll('.js-ranking li');
  if (stars) {
    stars.forEach((el) => {
      el.addEventListener('click', setStars);
    });
  }
}

export default init();
