function init() {
  const cancelContractButton = document.querySelector('button#contract-cancel');
  const submitContractButton = document.querySelector('button#contract-submit');

  if (cancelContractButton) {
    cancelContractButton.addEventListener('click', () => {
      window.history.back();
    });
  }

  if (submitContractButton) {
    submitContractButton.addEventListener('click', () => {
      localStorage.removeItem('reservation_season');
    });
  }
}

export default init();
