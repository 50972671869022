import formValidator from './modules/a_FormValidator';

function getFormFields() {
  return {
    name: { selector: '[name="client_register[name]"]', type: 'input' },
    lastname: { selector: '[name="client_register[lastname]"]', type: 'input' },
    email: { selector: '[name="client_register[email]"]', type: 'email' },
  };
}

function loadData() {
  let data = localStorage.getItem('register');
  const fields = getFormFields();
  if (!data || data === '') {
    return;
  }
  data = JSON.parse(data);
  Object.entries(fields).forEach((value) => {
    const key = value[0];
    const field = value[1];
    const element = document.querySelector(field.selector);
    if (!element) {
      return;
    }
    switch (field.type) {
      case 'date':
        element.value = data[key];
        break;
      case 'select':
        element.selectedIndex = data[key];
        element.dispatchEvent(new CustomEvent('change'));
        break;
      case 'checkbox':
        element.checked = data[key];
        element.dispatchEvent(new CustomEvent('change'));
        break;
      default:
        element.value = data[key];
        element.dispatchEvent(new CustomEvent('change'));
        break;
    }
  });
  localStorage.removeItem('register');
}

function sendForm(event) {
  const isValid = formValidator.validate(`#${event.target.id}`);
  if (isValid) {
    return true;
  }
  event.preventDefault();
  return false;
}

function toggleClubMyApk2Fields(event) {
  const form = event.target.closest('form');
  form.querySelector('.club-fields').classList.toggle('u-hidden');
}

function initClubMyApk2Field(form) {
  const clubMyApk2 = form.querySelector('[name="client_register[clubMyApk2]"]');
  if (clubMyApk2.checked) {
    form.querySelector('.club-fields').classList.toggle('u-hidden');
  }

  clubMyApk2.addEventListener('change', toggleClubMyApk2Fields);
}

function toggleLegalItem(event) {
  const links = document.querySelectorAll('.c-legal__link');
  const contents = document.querySelectorAll('[data-info-page]');
  const link = event.currentTarget;

  links.forEach((item) => {
    if (item.dataset.infoLink === link.dataset.infoLink) {
      item.classList.add('is-active');
    } else {
      item.classList.remove('is-active');
    }
  });

  contents.forEach((item) => {
    if (item.dataset.infoPage === link.dataset.infoLink) {
      item.classList.remove('u-hidden');
    } else {
      item.classList.add('u-hidden');
    }
  });
}

function initLegalModal(form) {
  const links = document.querySelectorAll('.c-legal__link');
  if (links) {
    links.forEach((link) => {
      link.addEventListener('click', toggleLegalItem);
    });
  }
}

function init() {
  const form = document.getElementById('js-form-register');
  if (form) {
    form.addEventListener('submit', sendForm);
    const password = document.querySelector('[name="client_register[password][first]"]');
    formValidator.validatePasswordWhenKeyUp(password);

    initClubMyApk2Field(form);
    //initLegalModal();
  }
  loadData();
}

export default init();
