function track() {
  const eventEl = document.querySelector('[data-pixel-event]');
  if (!eventEl) {
    return;
  }

  if (typeof fbq !== 'undefined') {
    const parametersEl = document.querySelector('[data-pixel-parameters]');
    if (parametersEl) {
      fbq('track', eventEl.dataset.pixelEvent, JSON.parse(parametersEl.dataset.pixelParameters));
      return;
    }
    fbq('track', eventEl.dataset.pixelEvent);
  }
}

export default track();
