/* eslint-disable */

export function clickProduct(productId, productName, productPrice = null) {
  if (typeof dataLayer !== "undefined" && dataLayer) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          products: [{
            name: productName,
            id: productId,
            price: productPrice,
          }],
        },
      },
    });
  }
}

export function beginCheckoutProduct(productId, productName, productPrice) {
  if (typeof dataLayer !== "undefined" && dataLayer) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
            option: 'checkout',
          },
          products: [
            {
              item_name: productName,
              item_id: productId,
              price: productPrice,
              index: 1,
              quantity: '1',
            },
          ],
        },
      },
    });
  }
}

export function clickPromotion(promotionName, promotionUrl) {
  if (typeof dataLayer !== "undefined" && dataLayer) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              name: promotionName,
            },
          ],
        },
      },
      eventCallback: () => {
        document.location = promotionUrl;
      },
    });
  }
}

export function purchaseEnd(data) {
  if (typeof dataLayer !== "undefined" && dataLayer) {
    const dataParse = JSON.parse(data);
    dataLayer.push({ ecommerce: null });
    dataParse.forEach((obj) => {
      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: obj.reference,
              revenue: obj.price,
              tax: obj.tax,
            },
            products: [
              {
                name: `web - ${obj.products}`,
                id: obj.reference,
                price: obj.price,
                item_id: obj.reference,
                item_name: `web - ${obj.products}`,
              },
            ],
          },
        },
      });
    });
  }
}

function notificatePurchase() {
  const purchaseElements = document.querySelectorAll('[data-purchase]');
  purchaseElements.forEach((el) => {
    const data = JSON.parse(el.dataset.purchase);
    if (typeof dataLayer !== "undefined" && dataLayer) {
      dataLayer.push({ ecommerce: null });
      dataLayer.push(data);
    }
  });
}

export default notificatePurchase();
/* eslint-enable */
