import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets: string[] = [
    "form",
    "email",
    "emailLabel",
    "chargerLabel",
    "amountLabel",
    "noLimitRadio",
    "listAmount",
  ];

  declare readonly formTarget: HTMLFormElement;
  declare readonly emailTarget: HTMLInputElement;
  declare readonly emailLabelTarget: HTMLLabelElement;
  declare readonly chargerLabelTarget: HTMLLabelElement;
  declare readonly amountLabelTarget: HTMLLabelElement;
  declare readonly noLimitRadioTarget: HTMLInputElement;
  declare readonly listAmountTarget: HTMLInputElement;

  connect(): void {}

  onChangeAmount(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.value) {
      this.noLimitRadioTarget.checked = false;
    }
    if (this.amountLabelTarget.classList.contains("o-text--danger")) {
      this.amountLabelTarget.classList.remove("o-text--danger");
    }
  }

  onNoLimitChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.listAmountTarget.querySelectorAll('input[type="radio"]').forEach((input) => {
        (input as HTMLInputElement).checked = false;
      });
    }
    if (this.amountLabelTarget.classList.contains("o-text--danger")) {
      this.amountLabelTarget.classList.remove("o-text--danger");
    }
  }

  submit(event: Event) {
    event.preventDefault();
    this.hiddenErrors();
    const formData = new FormData(this.formTarget);
    if (!this.isValidForm(formData)) {
      this.displayErrors(formData);
      this.doScrollToError(formData);
      return;
    }
    this.formTarget.submit();
  }

  onEmailKeyUp() {
    const formData = new FormData(this.formTarget);
    const email = formData.get("email") as string;
    if (this.isValidEmail(email)) {
      this.emailTarget.classList.remove("has-error");
      this.emailLabelTarget.classList.remove("o-text--danger");
    } else {
      this.emailTarget.classList.add("has-error");
      this.emailLabelTarget.classList.add("o-text--danger");
    }
  }

  isValidEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  isValidCharger(charger: string): boolean {
    return charger?.length > 0;
  }

  onChargerChange() {
    if (this.chargerLabelTarget.classList.contains("o-text--danger")) {
      this.chargerLabelTarget.classList.remove("o-text--danger");
    }
  }

  private hiddenErrors() {
    this.emailTarget.classList.remove("has-error");
    this.emailLabelTarget.classList.remove("o-text--danger");
    this.chargerLabelTarget.classList.remove("o-text--danger");
    this.amountLabelTarget.classList.remove("o-text--danger");
  }

  private doScrollToError(formData: FormData) {
    const email = formData.get("email") as string;
    const charger = formData.get("charger") as string;
    const maxAmount = formData.get("maxAmount") as string;
    const noLimits = formData.get("noLimits") as string;

    if (!this.isValidEmail(email)) {
      this.emailTarget.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    if (!this.isValidCharger(charger)) {
      this.chargerLabelTarget.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    if (!this.isValidAmount(maxAmount, noLimits)) {
      this.amountLabelTarget.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
  }

  private displayErrors(formData: FormData) {
    const email = formData.get("email") as string;
    const charger = formData.get("charger") as string;
    const maxAmount = formData.get("maxAmount") as string;
    const noLimits = formData.get("noLimits") as string;

    if (!this.isValidEmail(email)) {
      this.emailTarget.classList.add("has-error");
      this.emailLabelTarget.classList.add("o-text--danger");
    }

    if (!this.isValidCharger(charger)) {
      this.chargerLabelTarget.classList.add("o-text--danger");
    }

    if (!this.isValidAmount(maxAmount, noLimits)) {
      this.amountLabelTarget.classList.add("o-text--danger");
    }
  }

  private isValidAmount(maxAmount: string, noLimits: string): boolean {
    if (!maxAmount && noLimits !== "on") {
      return false;
    }
    return true;
  }

  private isValidForm(formData: FormData): boolean {
    const email = formData.get("email") as string;
    const charger = formData.get("charger") as string;
    const maxAmount = formData.get("maxAmount") as string;
    const noLimits = formData.get("noLimits") as string;

    if (!this.isValidEmail(email)) {
      return false;
    }

    if (!this.isValidCharger(charger)) {
      return false;
    }

    if (!this.isValidAmount(maxAmount, noLimits)) {
      return false;
    }

    return true;
  }
}
