import formValidator from '../modules/a_FormValidator';

function sendForm(event) {
  const isValid = formValidator.validate(`#${event.target.id}`);
  if (isValid) {
    return true;
  }
  event.preventDefault();
  return false;
}

function init() {
  const form = document.getElementById('js-form-login');
  if (form) {
    form.addEventListener('submit', sendForm);
  }
}

export default init();
