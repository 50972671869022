import formValidator from "../modules/a_FormValidator";

function sendForm(event) {
  const isValid = formValidator.validate(`#${event.target.id}`);
  if (isValid) {
    return true;
  }
  event.preventDefault();
  return false;
}

function init() {
  const form = document.getElementById('js-form-password-reset');
  if (form) {
    form.addEventListener('submit', sendForm);
    const password = document.querySelector('[name="recover_password_set[password][first]"]');
    formValidator.validatePasswordWhenKeyUp(password);
  }
}

export default init();
