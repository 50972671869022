import { tns } from '../../../../../node_modules/tiny-slider/src/tiny-slider';
import { clickPromotion } from '../modules/gtagmanager';

function initHome() {
// eslint-disable-next-line no-unused-vars
  const slider1 = tns({
    container: '.js-slider-home',
    preventScrollOnTouch: 'force',
    items: 1,
    mode: 'gallery',
    autoplay: true,
    autoplayTimeout: 5000,
    speed: 3000,
    controls: false,
    nav: true,
    autoplayButton: false,
  });

}

function pushClickPromotion(event) {
  event.preventDefault();
  if (event.currentTarget.title && event.currentTarget.href) {
    clickPromotion(event.currentTarget.title, event.currentTarget.href);
  }
}

function init() {
  if (document.querySelector('.js-slider-home')) {
    initHome();
  }

  const promoLinks = document.querySelectorAll('.c-slider--hero a');
  if (promoLinks) {
    promoLinks.forEach(link => {
        link.addEventListener('click', pushClickPromotion)
    })
  }
}

export default init();
