import {
  updateTimePicker,
  updateDatePicker,
  initializeTimePicker,
  initializeDatePicker,
} from "../modules/datepicker";
import formValidator from "../modules/a_FormValidator";
import { DateTime } from "luxon";

const form = document.getElementById("js-reservation-edit-form");
let frequencyDateIn = null;
let frequencyTimeIn = null;
let frequencyDateOut = null;
let frequencyTimeOut = null;
let modalContent = null;
let modalContentAmount = null;
let priceInput = null;
let changeAmountText = null;

function sendForm(event) {
  const isValid = formValidator.validate(`#${event.target.id}`);
  event.preventDefault();
  if (!isValid) {
    return false;
  }
  const modal = document.querySelector('[data-modal="edit-reservation"]');
  if (modal) {
    modal.classList.add("is-active");
  }
  return false;
}

function confirmForm() {
  form.submit();
}

function sendAjaxRequest(formData, endpoint) {
  const requestOptions = {
    method: "POST",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };

  return new Promise((resolve) => {
    fetch(endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      });
  });
}

function checkIfChangeAmount(price) {
  const priceFormated = price.replace(".", "").replace(",", ".");
  if (
    priceInput &&
    parseFloat(priceInput.value) !== parseFloat(priceFormated)
  ) {
    return true;
  }
  return false;
}

function getRatePrice() {
  const pRate = document.querySelector(".js-reservation-rate-text");
  if (pRate !== null) {
    const { priceUrl, productId, parkingId } = pRate.dataset;
    const formData = {};

    if (
      frequencyDateIn.value.length > 0 &&
      frequencyTimeIn.value.length > 0 &&
      frequencyDateOut.value.length > 0 &&
      frequencyTimeOut.value.length > 0
    ) {
      const outValueDate = new Date(
        `${frequencyDateOut.value} ${frequencyTimeOut.value}`
      ).getTime();
      const inValueDate = new Date(
        `${frequencyDateIn.value} ${frequencyTimeIn.value}`
      ).getTime();

      if (outValueDate <= inValueDate) {
        document
          .querySelector(".js-reservation-rate-text")
          .classList.add("c-form__error");
      } else {
        [...form.querySelectorAll(".js-reservation-frequency-input")].forEach(
          (element) => {
            formData[element.name] = element.value;
          }
        );

        const periodData = {
          frequency_date: formData["reservation_edit[frequency_date]"]
            ? formData["reservation_edit[frequency_date]"]
            : null,
          frequency_time: formData["reservation_edit[frequency_time]"]
            ? formData["reservation_edit[frequency_time]"]
            : null,
          frequency_date_out: formData["reservation_edit[frequency_date_out]"]
            ? formData["reservation_edit[frequency_date_out]"]
            : null,
          frequency_time_out: formData["reservation_edit[frequency_time_out]"]
            ? formData["reservation_edit[frequency_time_out]"]
            : null,
        };

        if (!document.querySelector(".js-reservation-rate-text")) {
          form.insertAdjacentHTML(
            "beforeBegin",
            '<label class="js-rate-text u-pb-0">Cargando</label>'
          );
        } else {
          document
            .querySelector(".js-reservation-rate-text")
            .classList.remove("c-form__error");
        }

        const requestData = {
          productId,
          parkingId,
          formData: periodData,
        };

        sendAjaxRequest(requestData, priceUrl).then((response) => {
          const { total, uuid } = response;
          if (total) {
            const purchaseUuid = document.querySelector(
              'input[name="reservation_edit[newPurchaseUuid]"'
            );
            purchaseUuid.value = uuid;
            const price = total;
            document.querySelector(
              ".js-reservation-rate-text"
            ).innerHTML = `La tarifa para la estancia seleccionada será de: ${price}€`;
            document
              .querySelector(".js-reservation-rate-text")
              .classList.remove("u-hidden");

            document.querySelector(
              ".js-reservation-rate-text-modal"
            ).innerHTML = `La tarifa para la estancia seleccionada será de: ${price}€`;
            document
              .querySelector(".js-reservation-rate-text-modal")
              .classList.remove("u-hidden");
            changeAmountText.classList.remove("u-hidden");

            if (checkIfChangeAmount(price)) {
              modalContent.style.display = "none";
              modalContentAmount.style.display = "inline";
              changeAmountText.style.display = "block";
              document.querySelector(
                ".js-reservation-rate-text"
              ).style.display = "block";
              document.querySelector(
                ".js-reservation-rate-text-modal"
              ).style.display = "block";
            } else {
              modalContent.style.display = "inline";
              modalContentAmount.style.display = "none";
              changeAmountText.style.display = "none";
              document.querySelector(
                ".js-reservation-rate-text"
              ).style.display = "none";
              document.querySelector(
                ".js-reservation-rate-text-modal"
              ).style.display = "none";
            }
          } else {
            document
              .querySelector(".js-reservation-rate-text")
              .classList.add("c-form__error");
            document.querySelector(".js-reservation-rate-text").innerHTML =
              "ha ocurrido un error";

            document
              .querySelector(".js-reservation-rate-text-modal")
              .classList.add("c-form__error");
            document.querySelector(
              ".js-reservation-rate-text-modal"
            ).innerHTML = "ha ocurrido un error";
          }
        });
      }
    }
  }
}

function pickerValueToDate(valueDate, valueTime = null) {
  let valueSplit = valueDate.split("-");
  const dateValue = new Date(
    `${parseInt(valueSplit[2])}-${parseInt(valueSplit[1])}-${parseInt(
      valueSplit[0]
    )}`
  );

  if (!valueTime) {
    dateValue.setHours(0);
    dateValue.setMinutes(0);
  } else {
    valueSplit = valueTime.split(":");
    dateValue.setHours(parseInt(valueSplit[0]));
    dateValue.setMinutes(parseInt(valueSplit[1]));
  }
  dateValue.setSeconds(0);
  return dateValue;
}

function calculateMaxDate(filteredDays) {
  let maxDate = filteredDays.length > 0 ? filteredDays[0] : null;
  maxDate = DateTime.fromJSDate(maxDate).minus({ days: 1 }).toJSDate();
  return maxDate;
}

function init() {
  if (form) {
    frequencyDateIn = form.querySelector(
      ".js-reservation-frequency-input[name='reservation_edit[frequency_date]']"
    );
    frequencyTimeIn = form.querySelector(
      ".js-reservation-frequency-input[name='reservation_edit[frequency_time]']"
    );
    frequencyDateOut = form.querySelector(
      ".js-reservation-frequency-input[name='reservation_edit[frequency_date_out]']"
    );
    frequencyTimeOut = form.querySelector(
      ".js-reservation-frequency-input[name='reservation_edit[frequency_time_out]']"
    );
    modalContent = document.getElementById("edit-modal-info");
    modalContentAmount = document.getElementById("edit-modal-import-info");
    priceInput = document.querySelector('input[name="reservation_edit[price]"');
    changeAmountText = document.getElementById("amount-change-information");
    let restrictedDays = JSON.parse(frequencyDateIn.dataset.restrictedDays);
    restrictedDays = restrictedDays
      .map((dateStr) => {
        return DateTime.fromISO(dateStr).toJSDate();
      })
      .sort((a, b) => a - b);

    form.addEventListener("submit", sendForm);
    const sendButton = document.querySelector(".js-confirm-form");
    if (sendButton) {
      sendButton.addEventListener("click", confirmForm);
    }

    document
      .querySelectorAll(".js-reservation-frequency-input")
      .forEach((element) => {
        element.addEventListener("change", (event) => {
          const dateNow = new Date();
          if (
            event.target.getAttribute("name") ===
            "reservation_edit[frequency_date]"
          ) {
            const dateValue = pickerValueToDate(event.target.value);
            if (frequencyTimeIn) {
              updateTimePicker(frequencyTimeIn, dateNow, dateValue);
            }
            if (frequencyDateOut) {
              const dateOut = pickerValueToDate(
                frequencyDateOut.dataset.default
              );
              const filteredDays = restrictedDays.filter(
                (date) => dateValue <= date
              );
              let maxDate = calculateMaxDate(filteredDays);

              updateDatePicker(
                frequencyDateOut,
                dateValue,
                (dateValue < dateOut ? dateOut : dateValue),
                maxDate
              );
            }
          }
          if (
            event.target.getAttribute("name") ===
            "reservation_edit[frequency_time_in]"
          ) {
            const timeIn = event.currentTarget.value || null;
            const dateIn = pickerValueToDate(
              frequencyDateIn.dataset.default,
              timeIn
            );
            if (frequencyTimeOut) {
              updateTimePicker(
                frequencyTimeOut,
                dateIn,
                frequencyTimeOut.dataset.default
              );
            }
          }
          if (
            event.target.getAttribute("name") ===
            "reservation_edit[frequency_date_out]"
          ) {
            const timeIn = frequencyTimeIn.dataset.default || null;
            const dateIn = pickerValueToDate(
              frequencyDateIn.dataset.default,
              timeIn
            );
            const dateOut = pickerValueToDate(
              event.currentTarget.value,
              frequencyTimeOut.dataset.default || null
            );
            updateDatePicker(
              frequencyDateOut,
              dateIn,
              dateOut < dateIn ? dateIn : dateOut
            );
            if (frequencyTimeOut) {
              updateTimePicker(
                frequencyTimeOut,
                dateIn,
                dateOut < dateIn ? dateIn : dateOut
              );
            }
          }
          if (
            event.target.getAttribute("name") ===
            "reservation_edit[frequency_time_out]"
          ) {
            const timeIn = frequencyTimeIn.dataset.default || null;
            const dateIn = pickerValueToDate(
              frequencyDateIn.dataset.default,
              timeIn
            );
            const dateOut = pickerValueToDate(
              frequencyDateOut.dataset.default,
              event.currentTarget.value
            );
            updateTimePicker(
              frequencyTimeOut,
              dateIn,
              dateOut < dateIn ? dateIn : dateOut
            );
          }
          getRatePrice();
        });
      });
    let defaultDateIn = null;  
    if (frequencyDateIn) {
      defaultDateIn = frequencyDateIn.dataset.default
        ? pickerValueToDate(
            frequencyDateIn.dataset.default,
            frequencyTimeIn ? frequencyTimeIn.dataset.default : null
          )
        : new Date();

      initializeDatePicker(frequencyDateIn, defaultDateIn, restrictedDays);
      if (frequencyTimeIn !== null) {
        initializeTimePicker(frequencyTimeIn, defaultDateIn);
      }
    }

    if (frequencyDateOut) {
      const defaultDateOut = pickerValueToDate(
        frequencyDateOut.dataset.default,
        frequencyTimeOut ? frequencyTimeOut.dataset.default : null
      );

      const filteredDays = restrictedDays.filter(
        (date) => defaultDateIn <= date
      );

      let maxDate = calculateMaxDate(filteredDays);

      initializeDatePicker(frequencyDateOut, defaultDateOut, [], maxDate);
      if (frequencyTimeOut !== null) {
        initializeTimePicker(frequencyTimeOut, defaultDateOut);
      }
    }
  }
}

export default init();
