import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        extensions: Array,
    };

    declare extensionsValue: string[];

    connect() {
        const links = this.element.querySelectorAll('a');
        const extensions = this.extensionsValue;

        links.forEach((link) => {
            const ext = link.getAttribute('href')?.match(/.*\.(.+)$/);
            if (ext && ext.length === 2) {
                if (extensions.includes(ext[1])) {
                    link.setAttribute('download', '');
                    link.setAttribute('target', '_blank');
                }
            }
        });
    }
}
