import { tns } from '../../../../../node_modules/tiny-slider/src/tiny-slider';

function initSlider() {
  // eslint-disable-next-line no-unused-vars
  const slider1 = tns({
    container: '.js-slider',
    preventScrollOnTouch: 'force',
    mouseDrag: true,
    items: 1,
    center: false,
    loop: true,
    controls: false,
    nav: true,
  });
}

function initSliderOthers() {
  // eslint-disable-next-line no-unused-vars
  const slider2 = tns({
    container: '.js-slider-others',
    mouseDrag: true,
    items: 2,
    center: false,
    loop: true,
    preventScrollOnTouch: 'auto',
    gutter: 15,
    controls: false,
    nav: true,
    responsive: {
      768: {
        items: 3,
        gutter: 20,
      },
      1150: {
        items: 4,
      },
    },
  });
}

function changeHover(event) {
  const hover = event.currentTarget;
  const section = document.getElementById(hover.dataset.hover);

  if (!section.classList.contains('is-active')) {
    if (hover.parentElement.parentElement.querySelector('.is-active')) {
      hover.parentElement.parentElement.querySelector('.is-active').classList.remove('is-active');
    }
    section.classList.add('is-active');
  }
}

function init() {
  if (document.querySelector('.js-slider')) {
    initSlider();
  }
  if (document.querySelector('.js-slider-others')) {
    initSliderOthers();
  }
  const hover = document.querySelectorAll('[data-hover]');
  if (hover) {
    hover.forEach((el) => {
      el.addEventListener('mouseover', changeHover);
    });
  }
}

export default init();
