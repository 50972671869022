import { Controller } from "@hotwired/stimulus";

declare var dataLayer: any[];

interface datalayerObjJson {
  productName: string;
  price: number;
  tax: number;
  paymentUuid: string;
}

export default class extends Controller {
  connect() {
    this.sendPurchaseGtm();
  }

  sendPurchaseGtm() {
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      const dataElement = document.querySelector('[data-google-parameters]') as HTMLElement;
      const data = dataElement.dataset.googleParameters;
      if (!data) {
        return false;
      }
      const dataParse:datalayerObjJson = JSON.parse(data);
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: dataParse.paymentUuid,
              revenue: dataParse.price,
              tax: dataParse.tax,
            },
            products: [
              {
                name: `web - ${dataParse.productName}`,
                id: dataParse.paymentUuid,
                price: dataParse.price,
                item_id: dataParse.paymentUuid,
                item_name: `web - ${dataParse.productName}`,
              },
            ],
          },
        },
      });
    }
  }
}