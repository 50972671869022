const inputs = document.querySelectorAll('[required]');
const formBtn = document.querySelector('.js-btn--form');

function processBtn() {
  const emptyInputs = [...inputs].filter((i) => i.value.length === 0);
  if (emptyInputs.length > 0) {
    formBtn.setAttribute('disabled', true);
  } else {
    formBtn.removeAttribute('disabled');
  }
}

function init() {
  if (formBtn) {
    processBtn();
    inputs.forEach((input) => {
      input.addEventListener('change', processBtn);
    });
  }
}

export default init();
