export default function verify(recaptchaKey, recaptchaUrl, callbackSuccess, callbackError) {
  grecaptcha.ready(() => {
    grecaptcha.execute(recaptchaKey, { action: 'submit' }).then((token) => {
      const formData = new FormData();
      formData.append('token', token);
      fetch(recaptchaUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.status === 200) {
            callbackSuccess();
          } else {
            callbackError();
          }
        })
        .catch(() => console.error('Internal server error'));
    });
  });
}
