const scrollContainers = document.querySelectorAll('[data-scroll-to]');
const header = document.querySelector('.c-header');

/**
 *
 * @param {*} datepickerInputs
 * @param {HTMLElement} destContainer
 */
function scrollToWhenDatePickerFilled(datepickerInputs, destContainer) {
  const filteredInputs = [...datepickerInputs].filter((d) => d.value.length === 0);

  if (filteredInputs.length === 0) {
    window.scrollTo(0, destContainer.offsetTop - destContainer.scrollTop - header.scrollHeight);
  }
}

/**
 *
 * @param {HTMLElement} container
 */
function processScrollContainers(container) {
  const datepickerInputs = container.querySelectorAll('[data-datepicker]');
  const destContainer = document.getElementById(container.dataset.scrollTo);
  if (datepickerInputs) {
    datepickerInputs.forEach((datepickerInput) => {
      datepickerInput.addEventListener('change', (event) => {
        if (event.currentTarget.hasAttribute('no-scroll')) {
          return;
        }
        scrollToWhenDatePickerFilled(datepickerInputs, destContainer);
      });
    });
  }
}

function init() {
  if (scrollContainers) {
    scrollContainers.forEach((container) => {
      processScrollContainers(container);
    });
  }
}

export default init();
