import flatpickr from 'flatpickr';
import { DateTime } from 'luxon';

const spanish = require('flatpickr/dist/l10n/es.js').default.es;

function initDatepicker(element) {
  const now = new Date();
  const { datepickerMinDate, datepickerMaxDate, value } = element.dataset;
  let defaultValue = null;
  if (value) {
    defaultValue = DateTime.fromFormat(value, 'yyyy-MM-dd');
  }
  flatpickr(element, {
    minDate: datepickerMinDate || now,
    maxDate: datepickerMaxDate || null,
    dateFormat: 'd-m-Y',
    locale: spanish,
    disableMobile: true,
    defaultDate: defaultValue?.toJSDate(),
  });
}

function initTimepicker(element) {
  const now = new Date();
  now.setHours(now.getHours() + 1);
  flatpickr(element, {
    minDate: now,
    defaultDate: now,
    dateFormat: 'H:i',
    locale: spanish,
    enableTime: true,
    noCalendar: true,
    disableMobile: true,
  });
}

export function updateTimePicker(element, minDate, defaultDate = null) {
  let now = (new Date());
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  if (element) {
    if (minDate && minDate > now) {
      flatpickr(element, {
        minDate,
        dateFormat: 'H:i',
        defaultDate: [null, false].includes(defaultDate) ? minDate : defaultDate,
        locale: spanish,
        enableTime: true,
        noCalendar: true,
        disableMobile: false,
      });
    } else {
      now = new Date();
      now.setHours(now.getHours() + 1);

      flatpickr(element, {
        minDate: now,
        dateFormat: 'H:i',
        defaultDate: [null, false].includes(defaultDate) ? now : defaultDate,
        locale: spanish,
        enableTime: true,
        noCalendar: true,
        disableMobile: false,
      });
    }
  }
}

export function initializeTimePicker(element, dateSet) {
  const now = (new Date());
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  flatpickr(element, {
    // 'minDate': now,
    dateFormat: 'H:i',
    defaultDate: dateSet,
    locale: spanish,
    enableTime: true,
    noCalendar: true,
    disableMobile: false,
  });
}

export function initializeDatePicker(element, dateSet, restrictedDays = [], maxDate = null) {
  const now = (new Date());
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  flatpickr(element, {
    // 'minDate': now,
    defaultDate: dateSet,
    dateFormat: 'd-m-Y',
    locale: spanish,
    disableMobile: true,
    disable: restrictedDays,
    maxDate,
  });
}

export function updateDatePicker(element, minDate, defaultDate = null, maxDate = null, restrictedDays = []) {
  if (element && minDate) {
    flatpickr(element, {
      minDate,
      defaultDate: [null, false].includes(defaultDate) ? minDate : defaultDate,
      dateFormat: 'd-m-Y',
      locale: spanish,
      disableMobile: true,
      maxDate,
      disable: restrictedDays,
    });
  }
}

function init() {
  const inputDateArray = document.querySelectorAll('[data-datepicker]');
  if (inputDateArray) {
    inputDateArray.forEach((el) => {
      if (el.dataset.datepicker === 'time') {
        initTimepicker(el);
      } else {
        initDatepicker(el);
      }
    });
  }
}

export default init();
