import formValidator from './modules/a_FormValidator';
import modal from './modal';

function toggleProduct(event) {
  if (event.target.nodeName !== 'svg' && event.target.nodeName !== 'path') {
    const parent = event.currentTarget;
    if (parent.classList.contains('is-active')) {
      parent.classList.remove('is-active');
    } else {
      parent.classList.add('is-active');
    }
  }
}

function sendForm(event) {
  const isValid = formValidator.validate(`#${event.target.id}`);
  if (isValid) {
    return true;
  }
  event.preventDefault();
  return false;
}

function setStars(event) {
  const element = event.currentTarget;
  const stars = Array.from(element.parentElement.querySelectorAll('li'));
  const index = stars.indexOf(element);

  stars.forEach((el, i) => {
    if (i <= index) {
      el.classList.add('is-active');
    } else {
      el.classList.remove('is-active');
    }
  });
  document.getElementById('jsRating').value = index + 1;
  document.getElementById('jsRatingForm').action = element.dataset.reservation;
  modal.switcher(event, 'rating');
}

function showQrModal(event) {
  const imageEl = event.currentTarget;
  const imageModalEl = document.querySelector('.jsModalQr');

  imageModalEl.src = imageEl.src;
  modal.switcher(event, 'qr');
}

function toggleLegalItem(event) {
  const links = document.querySelectorAll('.c-legal__link');
  const contents = document.querySelectorAll('[data-info-page]');
  const link = event.currentTarget;

  links.forEach((item) => {
    if (item.dataset.infoLink === link.dataset.infoLink) {
      item.classList.add('is-active');
    } else {
      item.classList.remove('is-active');
    }
  });

  contents.forEach((item) => {
    if (item.dataset.infoPage === link.dataset.infoLink) {
      item.classList.remove('u-hidden');
    } else {
      item.classList.add('u-hidden');
    }
  });
}

function initLegalModal() {
  const links = document.querySelectorAll('.c-legal__link');
  if (links) {
    links.forEach((link) => {
      link.addEventListener('click', toggleLegalItem);
    });
  }
}

function init() {
  const form = document.getElementById('js-form-profile');
  if (form) {
    form.addEventListener('submit', sendForm);
  }

  const ratingReview = document.getElementById('jsRatingForm');
  if (ratingReview) {
    ratingReview.addEventListener('submit', sendForm);
  }

  const toggleBtn = document.querySelectorAll('.js-toggle-detail');
  if (toggleBtn) {
    toggleBtn.forEach((el) => {
      el.addEventListener('click', toggleProduct);
    });
  }

  const stars = document.querySelectorAll('.js-ranking li');
  if (stars) {
    stars.forEach((el) => {
      el.addEventListener('click', setStars);
    });
  }

  const qrsShow = document.querySelectorAll('.js-qrShow');
  if (qrsShow) {
    qrsShow.forEach((qrShow) => {
      qrShow.addEventListener('click', showQrModal);
    });
  }

  const vehicleForm = document.getElementById('js-form-vehicle');

  if (vehicleForm) {
    vehicleForm.addEventListener('submit', sendForm);
  }
  
  initLegalModal();
}

export default init();
