export function switcher(event, modalink = null) {
  const modal = modalink || event.currentTarget.dataset.modalink;
  const searchModal = document.querySelector(`[data-modal="${modal}"]`);
  if (!searchModal.classList.contains('is-active')) {
    searchModal.classList.add('is-active');
    document.body.style.overflow = 'hidden';
  } else {
    searchModal.classList.remove('is-active');
    document.body.style.overflow = 'initial';
  }
}

export function init() {
  const openCloseButtons = document.querySelectorAll('[data-modalink]');
  const searchModal = document.querySelector('[data-modal]');
  if (openCloseButtons && searchModal) {
    openCloseButtons.forEach((el) => {
      el.addEventListener('click', switcher);
    });
  }
}

const modal = {
  init,
  switcher,
};

export default modal;
