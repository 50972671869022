import { normalizeSearchValue } from '../modules/utils';

function filterList(event) {
  const listElements = document.querySelectorAll('[data-city]');
  listElements.forEach((el) => {
    if (typeof event.detail === 'object') {
      if (el.dataset.id === event.detail.id) {
        el.classList.remove('u-hidden');
      } else {
        el.classList.add('u-hidden');
      }
    } else if (el.dataset.city === event.detail) {
      el.classList.remove('u-hidden');
    } else {
      el.classList.add('u-hidden');
    }
  });
}

function clearList(event) {
  if (event.detail < 12) {
    const listElements = document.querySelectorAll('[data-city].u-hidden');
    listElements.forEach((el) => {
      el.classList.remove('u-hidden');
    });
  }
}

function clickShowMore(event) {
  const listElements = document.querySelectorAll('[data-city]');
  listElements.forEach((el) => {
    if (event.detail !== el) {
      el.classList.add('u-hidden');
    } else {
      el.classList.remove('u-hidden');
    }
  });
}

function hideDelete() {
  document.querySelector('.js-delete-btn').classList.remove('u-hidden');
}

function filterListInput() {
  const listElements = document.querySelectorAll('[data-city]');
  const input = document.querySelector('.js-search-box-map');
  const filter = normalizeSearchValue(input.value);
  if (input.value.length > 0) {
    hideDelete();
  } else {
    document.querySelector('.js-delete-btn').classList.add('u-hidden');
  }

  for (let i = 0; i < listElements.length; i++) {
    const option = listElements[i];
    let searchValue = option.querySelector('p').textContent || option.querySelector('p').innerText;
    searchValue = normalizeSearchValue(searchValue);
    if (searchValue.includes(filter)) {
      option.classList.remove('u-hidden');
    } else {
      option.classList.add('u-hidden');
    }
  }
}

function deleteInput() {
  const input = document.querySelector('.js-search-box-map');
  input.value = '';
  filterListInput();
}

function init() {
  if (document.querySelector('[data-component="search-map"')) {
    document.addEventListener('eventSelectCity', filterList);
    document.addEventListener('eventSelectParking', filterList);
    document.addEventListener('eventChangeZoom', clearList);
    document.addEventListener('eventClickShowMore', clickShowMore);
  }

  const butttonDelete = document.querySelector('.js-delete-btn');
  if (butttonDelete) {
    butttonDelete.addEventListener('click', deleteInput);
  }

  const searchBox = document.querySelector('.js-search-box-map');
  if (searchBox) {
    searchBox.addEventListener('keyup', filterListInput);
    if (searchBox.value.length > 0) {
      filterListInput();
    }
  }
}

export default init();
