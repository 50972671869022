import { normalizeSearchValue } from '../modules/utils';

function filterDropdown() {
  const element = this.parentElement;
  const input = element.querySelector('.js-dropdown-search');
  const options = element.getElementsByTagName('li');
  const filter = normalizeSearchValue(input.value);

  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    let searchValue = option.textContent || option.innerText;
    searchValue = normalizeSearchValue(searchValue);
    if (searchValue.includes(filter)) {
      option.style.display = '';
    } else {
      option.style.display = 'none';
    }
  }
}

function toggleDropdown() {
  this.nextElementSibling.classList.toggle('is-active');
  if (this.nextElementSibling.children.length > 0) {
    this.nextElementSibling.children[0].focus();
  }
}

function selectElement() {
  const element = this.parentElement.parentElement.parentElement;
  const input = element.querySelector('.js-dropdown-btn');
  input.value = this.dataset.label;
  element.querySelector('.js-dropdown-layer').classList.toggle('is-active');
  element.querySelectorAll('li').forEach((option) => option.removeAttribute('style'));
}

function closeDropDown() {
  document.querySelectorAll('.js-dropdown-layer').forEach((div) => div.classList.remove('is-active'));
  // eslint-disable-next-line no-param-reassign
  document.querySelectorAll('.js-dropdown-layer .js-dropdown-search').forEach((input) => { input.value = ''; });
  document.querySelectorAll('.js-dropdown-layer li').forEach((option) => option.removeAttribute('style'));
}

function init() {
  document.querySelectorAll('.js-dropdown-btn').forEach((btn) => {
    btn.addEventListener('click', toggleDropdown);
  });
  document.querySelectorAll('.js-dropdown-layer li').forEach((btn) => {
    btn.addEventListener('click', selectElement);
  });
  document.querySelectorAll('.js-dropdown-search').forEach((btn) => {
    btn.addEventListener('keyup', filterDropdown);
  });
  if (document.querySelector('.js-dropdown')) {
    document.addEventListener('click', (e) => {
      if (!document.querySelector('.js-dropdown').contains(e.target)) {
        closeDropDown();
      }
    });
  }
}

export default init();
