import { getParentWithClass } from '../modules/utils';


function showError(event) {
  event.preventDefault();
  const element = getParentWithClass(event.target, 'js-parent-error');
  if (!element.classList.contains('is-active')) {
    element.classList.add('is-active');
    document.body.style.overflow = 'hidden';
  } else {
    element.classList.remove('is-active');
    document.body.style.overflow = 'initial';
  }
}

function init() {
  const showErrorElement = document.querySelectorAll('.js-error');
  if (showErrorElement) {
    showErrorElement.forEach((el) => {
      el.addEventListener('click', showError);
    });
  }
}

export default init();
