export enum ChargeSessionStatuses {
    CREATED = 'created',
    READY = 'ready',
    IN_USE = 'in-use',
    PENDING_FINISH = 'pending-finish',
    FINISHING = 'finishing',
    FINISHED = 'finished',
    ABANDONED = 'abandoned',
}

export type ChargeSession = {
    power: string;
    amount: string;
    duration: number;
    transmittedEnergy: string;
    status: ChargeSessionStatuses;
}
